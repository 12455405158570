<!-- 离职 -->
<template>
    <div>
        <!-- <van-nav-bar title="申请离职" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <div class="Dimission">
            <div v-if="flag == 1"></div>
            <div v-else-if='flag == 2'>
                <div v-if="list.dead_apply && list.dead_apply.status >= 0 && again == 0">
                    <div class="staff_info">
                        <div class="title">在职信息</div>
                        <div class="username">
                            <div class="left">
                                在职工厂
                            </div>
                            <div class="right">{{ list.basic.incumbent_factory_name }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                职位名称
                            </div>
                            <div class="right">{{ list.basic.incumbent_job }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                入职日期
                            </div>
                            <div class="right">{{ list.basic.employ_time }}</div>
                        </div>
                        <div class="username">
                            <div class="left">工期</div>
                            <div class="right">{{ list.basic.period }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                到期日期
                            </div>
                            <div class="right">{{ list.basic.dead_time }}</div>
                        </div>
                    </div>
                    <div class="staff_info">
                        <div class="title">离职申请信息</div>
                        <div class="username">
                            <div class="left">
                                提交时间
                            </div>
                            <div class="right">{{ list.dead_apply.apply_time }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                离职日期
                            </div>
                            <div class="right">{{ list.dead_apply.dead_time }}</div>
                        </div>
                        <div class="username" v-if="list.dead_apply.employ_days > -1">
                            <div class="left">
                                在职天数
                            </div>
                            <div class="right">{{ list.dead_apply.employ_days }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                离职原因
                            </div>
                            <div class="right">{{ list.dead_apply.dead_reason }}</div>
                        </div>
                    </div>
                    <div class="staff_info" v-if="list.dead_apply.status == 2 || list.dead_apply.status == 1">
                        <div class="title">驻厂审批信息</div>
                        <div class="username">
                            <div class="left">
                                审批时间
                            </div>
                            <div class="right">{{ list.dead_apply.audit_time }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                审批人
                            </div>
                            <div class="right">{{ list.dead_apply.audit_person }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                审批结果
                            </div>
                            <div class="right">{{ list.dead_apply.status_desc }}</div>
                        </div>
                        <div class="username" v-if="list.dead_apply.audit_note">
                            <div class="left">
                                审批备注
                            </div>
                            <div class="right">{{ list.dead_apply.audit_note }}</div>
                        </div>
                    </div>
                    <div style="text-align:left;margin-left:0.4rem;font-size: 0.37333rem;margin-bottom:0.3rem">{{ list.dead_apply.status == 2 ? '离职申请已被驳回！' : (list.dead_apply.status == 1 ? '离职申请已通过！' : '工作人员会尽快处理，请耐心等待审核结果！')}}</div>
                    <!-- <van-button v-if="list.dead_apply.status == 2" type="info" size="large" class="btn" @click="againApply">重新申请</van-button> -->
                    <div class="news_footer" v-if="list.dead_apply.status == 2">
                        <div class="news_footer_on" @click="againApply" >重新申请</div>
                        <!-- <div v-if="count.audit <= 0" style="background:#ccc">申请借支</div> -->
                    </div>
                </div>
                <div v-else>
                    <div class="staff_info">
                        <div class="title">员工信息</div>
                        <div class="username">
                            <div class="left">姓名</div>
                            <div class="right">{{ list.basic.name }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                手机号码
                            </div>
                            <div class="right">{{ list.basic.mobile }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                身份证号
                            </div>
                            <div class="right">{{ list.basic.card_num }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                在职工厂
                            </div>
                            <div class="right">{{ list.basic.incumbent_factory_name }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                职位名称
                            </div>
                            <div class="right">{{ list.basic.incumbent_job }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                入职日期
                            </div>
                            <div class="right">{{ list.basic.employ_time }}</div>
                        </div>
                        <div class="username">
                            <div class="left">工期</div>
                            <div class="right">{{ list.basic.period }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                到期日期
                            </div>
                            <div class="right">{{ list.basic.dead_time }}</div>
                        </div>
                    </div>
                    <div class="dimission_info">
                        <div class="title">离职信息</div>
                        <div class="username" @click="show = true">
                            <div class="left">
                                离职日期<span style="color:red">*</span>
                            </div>
                            <div class="right" :style="list.basic.leaveDate ? '' : 'color:#C8C9CC'">{{ list.basic.leaveDate || '请选择'}}</div>
                            <div class="right_icon" :style="list.basic.leaveDate ? 'color:#333' : ''" >
                                <van-icon name="arrow"  />
                            </div>
                        </div>
                        <div class="note_box">
                            <div class="left">
                                离职原因<span style="color:red">*</span>
                            </div>
                            <div class='note'>
                                <van-field v-model="list.basic.note" rows="2" autosize :border="true" type="textarea" maxlength="100" placeholder="请输入离职原因" show-word-limit />
                            </div>
                        </div>
                    </div>
                    <!-- <van-button type="info" size="large" class="btn" @click="toApplyDimission">下一步</van-button> -->
                    <div class="news_footer" v-if="is_show">
                        <div class="news_footer_on" :class="is_click ? 'd8' : ''" @click="toApplyDimission" >提交</div>
                        <!-- <div v-if="count.audit <= 0" style="background:#ccc">申请借支</div> -->
                    </div>
                    <van-popup v-model="show" position="bottom">
                        <van-datetime-picker v-model="currentDate" type="date" title="选择离职日期" :visible-item-count="4" :min-date="minDate" :max-date="maxDate" @confirm="confirm" @cancel="show = false" />
                    </van-popup>
                </div>
            </div>
            <div v-else>
                <div class="news_wenan">
                    <div class="news_wenan_img"><img class="news_bg_imh" src="../../assets/img/null_index.png" alt=""></div>
                </div>
                <div class="news_wenan_text">
                    <div class="news_wenan_text_on">{{text}}</div>
                </div>
            </div>
            <!-- <div v-else class="err">
                <van-icon name="clear" color="red" size="40px" />
                <div class="err_text">
                    {{text}}
                </div>
            </div> -->
        </div>
        <!-- 弹窗 -->
        <div class="cancl_tan" v-if="my_lizhi">
            <div class="cancl_tan_on">
                <div class="cancl_tan_on_title">提示</div>
                <div class="cancl_tan_on_cont">
                    <div class="cancl_tan_on_cont_ont">确认您已离职？离职后将无法借支</div>
                </div>
                <div class="cancl_tan_on_bont">
                    <div class="cancl_tan_on_bont_lt">取消</div>
                    <div class="cancl_tan_on_bont_rt">确认</div>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { Notify } from 'vant';
export default {
    //import引入的组件需要注入到对象中才能使用
    name: "Dimission",
    components: {},
    data() {
        //这里存放数据
        return {
            list: {},
            is_click:false,
            my_lizhi:false,
            again: 0, // 第一次申请，所以为0，重新申请就为1
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2025, 10, 1),
            currentDate: "",
            show: false,
            flag: 1,
            is_home: false, // 是否是从home页面过来的
            is_show:true,
            docmHeight: window.innerHeight, //默认屏幕高度
            showHeight: window.innerHeight, //实时屏幕高度

        };
    },
    watch: {
        showHeight: function (newValue) {
            if (this.isAndroidOrIOS() == 'android') {
                if (this.docmHeight > newValue) {
                    this.is_show = false
                } else {
                    this.is_show = true
                }
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name == 'Home') {
                vm.is_home = true;
            } else {
                vm.is_home = false;
            }
        });
    },
    created() {
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        if (this.$route.query.team_id) {
            localStorage.setItem('team_id', this.$route.query.team_id)
            localStorage.setItem('factory_id', this.$route.query.factory_id)
        }
        if (Object.values(this.$store.state.applyList).length > 0) {
            this.list = this.$store.state.applyList
        } else {
            this.onload()
        }
        window.onresize = () => {
            return (() => {
                this.showHeight = window.innerHeight;
            })();
        };
        this.again = this.$route.query.again ? this.$route.query.again : 0
    },
    //方法集合
    methods: {
        getQuery(variable) {
            var query = unescape(window.location.href);
            var lengths = query.split("?");
            var vars = lengths[lengths.length - 1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
        isAndroidOrIOS() {
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
                return 'android';
            }
            if (isiOS) {
                return 'ios';
            }
            return false;
        },
        onClickLeft() {
            if (this.again) {
                this.$router.replace({ path: '/dimission' })
                location.reload()
            } else {
                if (this.is_home) {
                    this.$router.go(-1)
                } else {
                    this.$router.replace({ path: '/home' })
                }
            }
        },
        confirm(e) {
            this.list.basic.leaveDate = this.getDate(e);
            this.show = false;
        },
        getDate(val) {
            var year = val.getFullYear();
            var month = val.getMonth() + 1;
            month = month < 10 ? "0" + month : month;
            var day = val.getDate();
            day = day < 10 ? "0" + day : day;
            return year + "-" + month + "-" + day;
        },
        onload() {
            this.Request('client/dead/apply','get',{ team_id: localStorage.getItem('team_id') }).then(res => {
                if (res.status == 0) {
                    if (res.data.basic.incumbent_factory_id == localStorage.getItem('factory_id') || !!localStorage.getItem('factory_id') == false || !this.getQuery('factory_id')) {
                        if (res.data.dead_apply && res.data.dead_apply.status == 0) { // 待审核
                            this.flag = 0
                            this.text = '抱歉，您的离职申请在审核中，请您耐心等待工作人员审核~'
                        } else {
                            this.flag = 2
                            this.list = res.data;
                            this.minDate = new Date(res.data.basic.apply_dead_time_range[0].replace(/-/g, '/'));
                            this.maxDate = new Date(res.data.basic.apply_dead_time_range[1].replace(/-/g, '/'));
                        }
                    } else {
                        this.flag = 0
                        this.text = '抱歉，您扫描的是其他工厂的离职码，请扫描您在职工厂的离职码进行申请离职'
                    }

                }else{
                     this.flag = 0
                     this.text = res.msg
                }
            })
        },
        isnull() {
            if (!this.list.basic.leaveDate) {
                Notify('离职日期不能为空');
                return false
            } else if (!this.list.basic.note) {
                Notify('离职原因不能为空');
                return false
            }
            else {
                return true
            }
        },
        // 重新申请
        againApply() {
            this.$router.replace({ path: '/dimission', query: { again: 1 } })
            location.reload()
        },
        toApplyDimission() {
            if (this.isnull()) {
                this.is_click =true;
                this.$store.commit('applyList_add', this.list)
                this.Request('client/dead/apply', 'post', {
                    factory_id: this.list.basic.incumbent_factory_id,
                    factory_member_id: this.list.basic.employ_member_id,
                    team_id: localStorage.getItem('team_id'),
                    dead_time: Math.round(new Date(this.list.basic.leaveDate).getTime() / 1000).toString(),
                    dead_reason: this.list.basic.note || '',
                    // factory_member_score: this.value1, // 工厂评分
                    // bus_member_score: this.value2, // 业务员评分
                    // factory_score: this.value3, // 驻厂评分
                    // comment: this.node || '',
                    is_anonymous: 1
                }).then(res => {
                    this.is_click = false
                    if (res.status == 0) {
                        this.$store.state.applyList = {}
                        this.$router.replace({ path: '/dimissionSuccess' })
                    }
                })
            }
        }

    },

};
</script>
<style scoped>
/* @import url(); 引入公共css类 */
.Dimission {
    width: 750px;
    /* padding-top: 90px; */
    padding-bottom: 100px;
    /* box-sizing: border-box; */
}

.staff_info,
.dimission_info {
    width: 750px;
    font-size: 28px;
    padding: 0 30px;
    box-sizing: border-box;
    background: white;
}
.staff_info {
    padding-top: 46px;
    margin-bottom: 20px;
}
.dimission_info {
    padding: 30px 30px 40px 30px;
}
.username {
    text-align: left;
    border-bottom: 1px solid #f8f8f8;
    display: flex;
    padding: 30px 0;
    justify-content: space-between;
    align-items: center;
}
.username:last-child {
    border-bottom: 0;
}
.title {
    font-size: 30px;
    text-align: left;
    font-weight: bold;
    color: #333333;
    /* margin-bottom: 10px; */
}
.left {
    /* width: 140px; */
    white-space: nowrap;
    text-align: left;
    margin-right: 20px;
}
.right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.placeholder {
    margin-left: 56px;
}
/* .right {
    color: gray;
} */
.right_icon {
    display: flex;
    width: 30px;
    align-items: center;
    justify-content: flex-end;
}
.note {
    border: 2px solid #f8f8f8;
    border-radius: 10px;
    z-index: 100;
    margin-top: 20px;
    overflow: hidden;
}

.btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
}
.note_box {
    margin-top: 30px;
}
.err {
    text-align: center;
    padding-top: 200px;
}
.err_text {
    font-size: 30px;
    width: 500px;
    margin: 20px auto;
}
</style>
